<template>
  <el-dialog
    :title="isEdit ? '编辑类别' : '添加类别'"
    width="500px"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    v-loading="loading"
    custom-class="category-manage-dialog">
    <el-form
        class="job-category-from"
        ref="jobCategoryForm"
        label-width="80px"
        label-position="left"
        :model="jobCategoryForm"
        :rules="jobCategoryFormRules">
        <el-form-item
            label="类别名称"
            prop="jobCategoryName">
            <el-input
                class="parent-category-input"
                placeholder="请填写"
                v-model="jobCategoryForm.jobCategoryName"
                :maxlength="50"
            ></el-input>
        </el-form-item>
        <el-form-item
            label="上级类别"
            prop="parentId">
            <el-cascader
                ref="parentCategorySelect"
                class="parent-category-select"
                popper-class="category-select-popper"
                placeholder="请选择"
                :disabled="isEdit"
                v-model="jobCategoryForm.parentId"
                :options="jobCategoryList"
                :props="{
                    value: 'id',
                    label: 'name',
                    children: 'subNodes',
                    checkStrictly: true,
                    emitPath: false
                }"
                @visible-change="handleVisibleChange"
                @change="handleParentCategory"
            ></el-cascader>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="cancelHandle">取消</el-button>
        <el-button type="primary" @click="confirmHandle">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import JobCategoryService from '#/js/service/jobCategoryService.js';
export default {
    data() {
        return {
            isEdit: false,
            dialogVisible: false,
            loading: false,
            jobCategoryForm: {
                jobCategoryName: "",
                parentId: ""
            },
            originJobCategoryName: '',
            jobCategoryFormRules: {
                jobCategoryName: [
                    { required: true, message: '类别名称不能为空', trigger: ['blur', 'change'] }
                ],
                parentId: [
                    { required: true, message: '上级类别不能为空', trigger: ['blur', 'change'] }
                ]
            },
            jobCategoryList: [],
            row: {},
        }
    },
    methods: {
        show(isEdit = false, params) {
            this.row = params;
            this.isEdit = isEdit;
            if(this.isEdit) {
                if(!params.parentId) {
                    params.parentId = 0;
                }
                Object.assign(this.jobCategoryForm, params);
                this.jobCategoryForm.jobCategoryName = params.name;
                this.originJobCategoryName = params.name;
            }
            this.getJobCategoryList();
            this.dialogVisible = true;
        },
        getJobCategoryList() {
            this.loading = true;
            const params = {
                hasChildrenJobCategory: true,
                hasJobCount: true,
            };
            JobCategoryService
            .jobCategoriesTree(params)
            .then(res => {
                this.loading = false;
                let list = [{
                    id: 0,
                    level: 0,
                    name: "全部类别",
                    subNodes: [],
                }];
                if(res) {
                    res.some(item => {
                        if(item.subNodes && item.subNodes.length) {
                            item.subNodes.forEach((sub, index) => {
                                if(sub.level === 2) {
                                    sub.subNodes = null;
                                }
                            })
                        }
                    })
                    list[0].subNodes = res;
                    this.jobCategoryList = list;
                } else {
                    this.jobCategoryList = [];
                }
            }).catch(err => {
                this.loading = false;
            })
        },
        handleVisibleChange(visible) {
            // let item = this.$refs.parentCategorySelect.getCheckedNodes();
            // if(item.length && item[0] && visible) {
            //     this.$nextTick(() => {
            //         let popperElm = this.$refs.parentCategorySelect.popperElm;
            //         popperElm.querySelectorAll('.el-cascader-menu')[item[0].data.level].scrollIntoView();
            //     })
            // }
        },
        handleParentCategory() {
            let item = this.$refs.parentCategorySelect.getCheckedNodes();
            if(item[0].data.level >= 3) {
                shortTips("职位类别上限为三级");
                this.$nextTick(() => {
                    this.jobCategoryForm.parentId = "";
                })
            }
        },
        cancelHandle() {
            this.dialogVisible = false;
            this.isEdit = false;
            this.jobCategoryForm = {
                jobCategoryName: "",
                parentId: ""
            };
            this.$refs.jobCategoryForm.resetFields();
        },
        confirmHandle() {
            this.$refs.jobCategoryForm.validate(isValid => {
                if(isValid) {
                    
                    if(this.isEdit) {
                        if(!!this.originJobCategoryName && this.originJobCategoryName == this.jobCategoryForm.jobCategoryName) {
                            return shortTips("职位类别名称未修改!")
                        }
                        this.loading = true;
                        let params = Object.assign({}, this.jobCategoryForm);
                        delete params.parentId;
                        JobCategoryService
                            .jobCategoriesEdit(params)
                            .then(res => {
                                shortTips("编辑成功!")
                                // this.$emit('refresh');
                                this.$emit('update-row', this.row, 'name', this.jobCategoryForm.jobCategoryName);
                                this.cancelHandle();
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    } else {
                        this.loading = true;
                        let params = Object.assign({}, this.jobCategoryForm);
                        params.parentId = params.parentId === -1 ? 0 : params.parentId;
                        console.log(params);
                        JobCategoryService
                            .jobCategoriesAdd(params)
                            .then(res => {
                                shortTips("添加成功!")
                                this.$emit('refresh');
                                this.cancelHandle();
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                } else {
                    console.log("error submit")
                }
            })
        }
    }
}
</script>

<style lang="scss" scope>
    .category-manage-dialog {
        .job-category-from {
            .parent-category-input.el-input {
                width: 380px;
            }
            .parent-category-select.el-cascader {
                width: 380px;
            }
        }
    }
</style>

<style lang="scss">
.category-select-popper.el-cascader__dropdown {
    max-width: 540px;
    overflow-x: auto;
    .el-cascader-menu {
        .el-cascader-menu__list {
            padding: 0;
        }
        .el-cascader-node {
            padding: 0;
            height: 40px;
            line-height: 40px;
            &.in-active-path {
                font-weight: normal;
            }
            &.in-checked-path {
                font-weight: bold;
            }
            label {
                opacity: 0;
                width: calc(100% - 40px);
                position: absolute;
                z-index: 10;
            }
            .el-cascader-node__label {
                position: relative;
                height: 40px;
                max-width: calc(100% - 40px);
            }
        }
    }
}
</style>