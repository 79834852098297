var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "job-set-table",
    },
    [
      _vm.tableData && _vm.tableData.length > 0
        ? _c("dragTreeTable", {
            attrs: {
              data: {
                columns: _vm.columns,
                lists: _vm.tableData,
                custom_field: {
                  lists: "subNodes",
                  parent_id: "parentId",
                },
              },
              onDrag: _vm.onTreeDataChange,
              beforeDragOver: _vm.handleBeforeDragOver,
              border: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "span",
                        {
                          attrs: {
                            title:
                              row.name.length > 20
                                ? row.name
                                : "长按可拖动职位类别",
                          },
                        },
                        [_vm._v(_vm._s(row.name))]
                      ),
                    ]
                  },
                },
                {
                  key: "enable",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "cell-wrap",
                          on: {
                            click: function ($event) {
                              return _vm.handleClick($event)
                            },
                          },
                        },
                        [
                          ![-1, 0].includes(row.id)
                            ? _c("el-switch", {
                                attrs: {
                                  disabled:
                                    row.level > 1 && row.status == "disable",
                                  value: _vm.transformEnable(row.enable),
                                },
                                on: {
                                  change: (val) =>
                                    _vm.handleStatusChange(row, val),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "action",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "cell-wrap",
                          on: {
                            click: function ($event) {
                              return _vm.handleClick($event)
                            },
                          },
                        },
                        [
                          row.id > 0
                            ? _c(
                                "el-link",
                                {
                                  staticStyle: { "margin-right": "20px" },
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.mergeJobCategory(row)
                                    },
                                  },
                                },
                                [_vm._v("合并")]
                              )
                            : _vm._e(),
                          row.id > 0
                            ? _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editJobCategory(row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2008939280
            ),
          })
        : _c("el-empty", { attrs: { description: "暂无数据" } }),
      _c("category-merge-dialog", {
        ref: "categoryMergeDialog",
        on: {
          refresh: function ($event) {
            return _vm.$emit("refresh")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }