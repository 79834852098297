<template>
  <el-dialog
    title="合并"
    width="500px"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    v-loading="loading"
    custom-class="category-merge-dialog">
    <div class="category-merge-tip">
        合并后，该类别下的职位的职位类别将替换成你选中的职位类别。
    </div>
    <el-form :model="form">
        <el-form-item label="源类别">
            <el-cascader
                
                class="merge-category-select"
                popper-class="category-select-popper"
                placeholder="请选择你要合并的职位类别"
                v-model="form.source"
                :options="jobCategoryList"
                disabled
                :props="{
                    value: 'id',
                    label: 'name',
                    children: 'subNodes',
                    checkStrictly: true,
                }"
            ></el-cascader>
        </el-form-item>
        <el-form-item label="目标类别">
            <el-cascader
                ref="mergeCategorySelect"
                class="merge-category-select"
                popper-class="category-select-popper"
                placeholder="请选择你要合并的职位类别"
                v-model="mergeId"
                :options="jobCategoryList"
                :props="{
                    value: 'id',
                    label: 'name',
                    children: 'subNodes',
                    checkStrictly: checkStrictly,
                }"
                @visible-change="handleVisibleChange"
                @change="handleMergeCategory"
            ></el-cascader>
            <!-- emitPath: false,  -->
            <!-- #F56C6C -->
            <p v-if="showTip" class="tip">源类别下没有子类别，请选择无子类别的目标类别</p>
            
        </el-form-item>

    </el-form>
    
    <span slot="footer" class="dialog-footer">
        <el-button @click="cancelHandle">取消</el-button>
        <el-button type="primary" @click="confirmHandle">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import JobCategoryService from '#/js/service/jobCategoryService.js';
export default {
    data() {
        return {
            dialogVisible: false,
            loading: false,
            id: "",
            mergeId: "",
            jobCategoryList: [],
            form: {
                source: '',
                mergeId: '', // 目标
            },
            showTip: false, //  源类别下没有子类别，请选择无子类别的目标类别提示
            checkStrictly: true,
        }
    },
    methods: {
        show(it) {
            console.log(`show:`, it)
            this.id = it.id;
            this.form.source = it.id;
            this.showTip = !it.subNodes;
            if(this.showTip) {
                this.checkStrictly = false;
            }
            this.getJobCategoryList();
            this.dialogVisible = true;
        },
        getJobCategoryList() {
            this.loading = true;
            const params = {
                hasChildrenJobCategory: true,
                hasJobCount: true,
            };
            JobCategoryService
            .jobCategoriesTree(params)
            .then(res => {
                this.loading = false;
                if(res) {
                    this.jobCategoryList = res;
                } else {
                    this.jobCategoryList = [];
                }

                // 将已经选择的项disable掉
                if(this.id) {
                    this.disabledSourceId(this.id)
                }
            }).catch(err => {
                this.loading = false;
                if(err.error && err.error.description) {
                    shortTips(err.error.description)
                } else {
                    shortTips("系统异常，请稍后再试")
                }
            })
        },
        disableTreeNode(tree, id) {
            tree.forEach(it => {
                if(it.id == id) {
                    it.disabled = true;
                } else {
                    if(it.subNodes && it.subNodes.length>0) {
                        this.disableTreeNode(it.subNodes, id);
                    }
                }
            });
        },
        disabledSourceId(id) {
            this.disableTreeNode(this.jobCategoryList, id);
        },
        handleVisibleChange(visible) {
            let item = this.$refs.mergeCategorySelect.getCheckedNodes();
            if(item.length && visible) {
                this.$nextTick(() => {
                    let popperElm = this.$refs.mergeCategorySelect.popperElm;
                    popperElm.querySelectorAll('.el-cascader-menu')[item[0]?.level]?.scrollIntoView();
                })
            }
        },
        handleMergeCategory(value) {
            console.log(`handleMergeCategory:`, value)
            if(this.mergeId === this.id) {
                shortTips("请勿选择当前需要合并的类别");
                this.$nextTick(() => {
                    this.mergeId = ""
                })
            }
        },
        cancelHandle() {
            this.dialogVisible = false;
            this.mergeId = "";
        },
        confirmHandle() {
            if(this.mergeId) {
                this.$confirm(`确定进行合并？操作后无法撤销`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    this.loading = true;
                    JobCategoryService.
                        jobCategoriesMerge({
                            fromId: this.id,
                            toId: this.mergeId[this.mergeId.length-1],
                        }).then(res => {
                            this.loading = false;
                            shortTips("合并成功");
                            this.$emit('refresh');
                            this.cancelHandle();
                        }).catch(err => {
                            this.loading = false;
                        });
                });
                
            } else {
                shortTips("请选择要合并的类型");
            }
        }
    }
}
</script>

<style lang="scss" scope>
    .category-merge-dialog {
        .category-merge-tip {
            margin: 10px auto;
        }
        .merge-category-select.el-cascader {
            width: 100%;
        }
        .tip{
            color: #F56C6C;
            line-height: 36px;
        }
    }
</style>

<style lang="scss">
.category-select-popper.el-cascader__dropdown {
    max-width: 540px;
    overflow-x: auto;
    .el-cascader-menu {
        .el-cascader-menu__list {
            padding: 0;
        }
        .el-cascader-node {
            padding: 0;
            height: 40px;
            line-height: 40px;
            &.in-active-path {
                font-weight: normal;
            }
            &.in-checked-path {
                font-weight: bold;
            }
            .el-cascader-node__label {
                height: 40px;
                max-width: calc(100% - 20px);
            }
        }
    }
}
</style>