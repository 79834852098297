var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "合并",
        width: "500px",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        "custom-class": "category-merge-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "category-merge-tip" }, [
        _vm._v(
          "\n      合并后，该类别下的职位的职位类别将替换成你选中的职位类别。\n  "
        ),
      ]),
      _c(
        "el-form",
        { attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "源类别" } },
            [
              _c("el-cascader", {
                staticClass: "merge-category-select",
                attrs: {
                  "popper-class": "category-select-popper",
                  placeholder: "请选择你要合并的职位类别",
                  options: _vm.jobCategoryList,
                  disabled: "",
                  props: {
                    value: "id",
                    label: "name",
                    children: "subNodes",
                    checkStrictly: true,
                  },
                },
                model: {
                  value: _vm.form.source,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "source", $$v)
                  },
                  expression: "form.source",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "目标类别" } },
            [
              _c("el-cascader", {
                ref: "mergeCategorySelect",
                staticClass: "merge-category-select",
                attrs: {
                  "popper-class": "category-select-popper",
                  placeholder: "请选择你要合并的职位类别",
                  options: _vm.jobCategoryList,
                  props: {
                    value: "id",
                    label: "name",
                    children: "subNodes",
                    checkStrictly: _vm.checkStrictly,
                  },
                },
                on: {
                  "visible-change": _vm.handleVisibleChange,
                  change: _vm.handleMergeCategory,
                },
                model: {
                  value: _vm.mergeId,
                  callback: function ($$v) {
                    _vm.mergeId = $$v
                  },
                  expression: "mergeId",
                },
              }),
              _vm.showTip
                ? _c("p", { staticClass: "tip" }, [
                    _vm._v("源类别下没有子类别，请选择无子类别的目标类别"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }