var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-permission-set" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "job-set",
      },
      [
        _c("div", { staticClass: "job-set-top" }, [
          _c(
            "div",
            { staticClass: "job-set-operate" },
            [
              _c(
                "div",
                { staticClass: "job-set-switch" },
                [
                  _c("el-switch", {
                    attrs: {
                      width: 40,
                      "active-color": "#38bc9d",
                      "inactive-color": "#999999",
                    },
                    on: { change: _vm.setOpenStatus },
                    model: {
                      value: _vm.isOpen,
                      callback: function ($$v) {
                        _vm.isOpen = $$v
                      },
                      expression: "isOpen",
                    },
                  }),
                  _c("span", { staticClass: "job-set-switch-text" }, [
                    _vm._v("让成员填写做单方向"),
                  ]),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "add-job-type-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.addJobCategory },
                },
                [_vm._v("\n                    添加类别\n                ")]
              ),
            ],
            1
          ),
        ]),
        _c("job-set-table", {
          ref: "jobSetTable",
          attrs: { tableData: _vm.list },
          on: {
            refresh: _vm.getList,
            editCategory: _vm.editCategory,
            deleteCategory: _vm.deleteCategory,
            "after-drag": _vm.afterDrag,
            "update-row": _vm.updateRow,
          },
        }),
        _c("div", { staticClass: "work-table-footer" }),
        _c("category-manage-dialog", {
          ref: "categoryManageDialog",
          on: { refresh: _vm.getList, "update-row": _vm.updateRow },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }