<template>
    <div class="user-permission-set">
        <div class="job-set" v-loading="loading">
            <div class="job-set-top">
                <!-- <div class="job-set-title">
                    系统职位类别
                </div> -->
                <div class="job-set-operate">
                    <div class="job-set-switch">
                        <el-switch
                            v-model="isOpen"
                            :width="40"
                            active-color="#38bc9d"
                            inactive-color="#999999"
                            @change="setOpenStatus">
                        </el-switch>
                        <span class="job-set-switch-text">让成员填写做单方向</span>
                    </div>
                    <el-button
                        class="add-job-type-btn"
                        type="primary"
                        @click="addJobCategory">
                        添加类别
                    </el-button>
                </div>
            </div>
            <job-set-table
                ref="jobSetTable"
                :tableData="list"
                @refresh="getList"
                @editCategory="editCategory"
                @deleteCategory="deleteCategory"
                @after-drag="afterDrag"
                @update-row="updateRow"
            ></job-set-table>
            <!-- <table-page-tab
                :filterIndex="0"
                :pageTabs="pageTabJson"
            ></table-page-tab> -->
            <div class="work-table-footer"></div>
            <category-manage-dialog
                ref="categoryManageDialog"
                @refresh="getList"
                @update-row="updateRow"
            ></category-manage-dialog>
        </div>
    </div>
</template>

<script>
import JobSetTable from './layout/job-set-table.vue';
import CategoryManageDialog from './components/category-manage-dialog.vue';
import JobCategoryService from '#/js/service/jobCategoryService.js';
// import TablePageTab from '#/component/workTable/table-page-tab.vue';
// import pageTabJson from './config/pageTab.json';
export default {
    name: "JobSet",
    components: {
        JobSetTable,
        CategoryManageDialog,
        // TablePageTab
    },
    data() {
        return {
            loading: true,
            isOpen: false,
            list: [],
            params: {},
            manageRow: {}
        }
    },
    computed: {
        // pageTabJson() {
        //     if(this.$store.state.user.userInfo.isCFUser) {
        //         return pageTabJson.CFUser;
        //     }else {
        //         return pageTabJson.notCFUser;
        //     }
        // },
        isHuntingCompanySetPermission() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('UserPermissionSet');
        },
    },
    mounted() {
        if(this.$store.state.user.userInfo.isAdministrator || this.isHuntingCompanySetPermission) {
            this.loading = false;
            this.getJobTagSet();
            this.getList();
        } else {
            shortTips("权限不足，请联系管理员")
            let tabItems = JSON.parse(sessionStorage.getItem("tabItems"));
            let _index = tabItems.findIndex(item => item.enTitle === "JobSet");
            tabItems.splice(_index, 1);
            sessionStorage.setItem('tabItems', JSON.stringify(tabItems));
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }
    },
    methods: {
        getJobTagSet() {
            JobCategoryService
                .jobCategoriesGetOption()
                .then(res => {
                    console.log(`jobCategoriesGetOption`, res)
                    this.isOpen = res;
                })
                .catch(err => {})
        },
        disableNodeAndChildren(node) {  
            node.status = 'disable';  
            for (let i = 0; i < node.subNodes?.length; i++) {
                this.disableNodeAndChildren(node.subNodes[i]);
            }  
        },
        getList() {
            const params = {
                hasChildrenJobCategory: true,
                hasJobCount: true,
            };
            this.$refs.jobSetTable.loading = true;
            JobCategoryService
                .jobCategoriesTree(params)
                .then(res => {
                    console.log(`jobCategoriesTree:`, res)
                    this.list = res;

                    let list = [{
                        id: -1,
                        level: 0,
                        name: "全部类别",
                        subNodes: [],
                        jobCount: 0,
                        enable: 0,
                        open: true,
                    }];
                    if(res) {
                        let jobCount = 0;
                        res.forEach((item, index) => {
                            if(index < res.length - 1) {
                                jobCount+= item.jobCount;
                            }
                            if(item.subNodes && item.subNodes.length) {
                                this.setParentId(item)
                            }
                        });

                        // 将所有子类全设置为disable
                        res.forEach(it => {
                            if(it.enable === 1) {
                                this.disableNodeAndChildren(it);
                            }
                        });

                        list[0].jobCount = jobCount;
                        list[0].subNodes = res.slice(0, res.length - 1);
                        // list[0].subNodes = res;
                        // list[0].hasChildren = true;
                        list.push(res[res.length - 1]);
                        this.list = list;
                        // this.$nextTick(() => {
                        //     if(this.$refs.jobSetTable.expands.length === 0) {
                        //         this.$refs.jobSetTable.expands.push('-1');
                        //     }
                        // });
                    } else {
                        this.list = list;
                    }
                    console.log(`this.list:`, this.list)
                })
                .finally(() => {
                    this.$refs.jobSetTable.loading = false;
                });
        },
        getList1() {
            let params = Object.assign({}, this.params);
            this.$refs.jobSetTable.loading = true;
            JobCategoryService.getCategoryList({
                firmId: this.$store.state.user.userInfo.firmId,
                hasChildrenJobCategory: true,
                hasJobCount: true
            }).then(res => {
                this.$refs.jobSetTable.loading = false;
                let list = [{
                    id: -1,
                    level: 0,
                    name: "全部类别",
                    children: [],
                    jobCount: 0
                }];
                if(res) {
                    let jobCount = 0;
                    res.forEach((item, index) => {
                        if(index < res.length - 1) {
                            jobCount+= item.jobCount;
                        }
                        if(item.children && item.children.length) {
                            this.setParentId(item)
                        }
                    })
                    list[0].jobCount = jobCount;
                    list[0].children = res.slice(0, res.length - 1);
                    list.push(res[res.length - 1]);
                    this.list = list;
                    this.$nextTick(() => {
                        if(this.$refs.jobSetTable.expands.length === 0) {
                            this.$refs.jobSetTable.expands.push('-1');
                        }
                    });
                } else {
                    this.list = list;
                }
                this.manageRow = {};
            }).catch(err => {
                this.$refs.jobSetTable.loading = false;
            })
        },
        setParentId(jobCategory, parentId) {
            if(parentId) {
                jobCategory.parentId = parentId;
            }
            if(jobCategory.subNodes && jobCategory.subNodes.length) {
                jobCategory.subNodes.forEach(item => {
                    this.setParentId(item, jobCategory.id);
                })
            }
        },
        setOpenStatus(value) {
            this.loading = true;
            this.isOpen = value;
            
            // JobCategoryService.setJobCategoryFilledStatus({
            //     mustFilledIn: value
            // })
            JobCategoryService
                .jobCategoriesEditOption({
                    isMustFill: value
                })
                .then(res => {
                    this.loading = false;
                    shortTips("设置成功");
                })
                .catch(err => {
                    this.loading = false;
                    shortTips("设置失败，请稍后重试");
                    this.isOpen = !value;
                });
        },
        addJobCategory() {
            this.$refs.categoryManageDialog.show(false);
        },
        editCategory(category) {
            this.manageRow = category;
            this.$refs.categoryManageDialog.show(true, category);
        },
        deleteCategory(category) {
            this.$refs.jobSetTable.loading = true;
            JobCategoryService.deleteJobCategory({
                jobCategoryId: category.id
            }).then(res => {
                this.$refs.jobSetTable.loading = false;
                shortTips("删除成功!");
                this.getList();
            }).catch(err => {
                this.$refs.jobSetTable.loading = false;
                if(err.error && err.error.description) {
                    shortTips(err.error.description)
                } else {
                    shortTips("系统异常，请稍后再试")
                }
            })
        },
        afterDrag(list) {
            console.log(`AfterDrag:`, list)
            this.list = list;
            console.log(this.list)


        },
        updateRow(row, type, val) {
            switch(type) {
                case 'enable':
                    // enabled: !val ? 1 : 0, // 0为启用，1为禁用
                    this.list.forEach(it =>{
                        this.updateNode(it, row.id, type, !val ? 1 : 0);
                    });
                    break;
                case 'name':
                    this.list.forEach(it =>{
                        this.updateNode(it, row.id, type, val);
                    });
                    break;
                default:
                    break;
            }
        },
        updateNode(node, nodeId, propName, propValue) {  
            if (node.id === nodeId) {  
                node[propName] = propValue;  
                return;  
            }  
            if (node.subNodes) {  
                for (let i = 0; i < node.subNodes.length; i++) {  
                    this.updateNode(node.subNodes[i], nodeId, propName, propValue);  
                }  
            }  
        },
    }
}
</script>

<style lang="scss" scope>
.user-permission-set {
    height: 100%;
    .job-set {
        height: 100%;
        min-height: 500px;
        padding: 10px 20px 30px 20px;
        background: url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
        display: flex;
        flex-direction: column;
        &-top {
            padding: 0 20px;
            background-color: #fff;
        }
        &-title {
            color: $primary;
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            margin-top: 14px;
        }
        &-operate {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 48px;

            .job-set-switch {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .job-set-switch-text {
                font-size: 14px;
                color: #666;
                margin-left: 10px;
            }

            /deep/ .el-switch {
                &__core:before {
                    content: '关';
                    position: absolute;
                    top: 1px;
                    width: 16px;
                    height: 16px;
                    line-height: 16px;
                    text-align: center;
                    left: 100%;
                    margin-left: -18px;
                    font-size: 12px;
                    color: #fff;
                }

                &.is-checked {
                    .el-switch__core:before {
                        content: '开';
                        left: 1px;
                        margin-left: 0;
                    }
                }
            }
        }
        .work-table-footer {
            // margin-top: 46px;
            margin: 18px;
            height: 28px;
            overflow: unset;
        }
    }
}
</style>