<template>
    <div
        class="job-set-table"
        v-loading="loading"
    >
        <!-- <el-table
            :data="tableData"
            :expand-row-keys="expands"
            :indent="26"
            :row-key="getRowKeys"
            :tree-props="{children: 'subNodes'}"
            border
            height="668"
            style="width: 100%"
            tooltip-effect="light"
            v-loading="loading"
        >
            <el-table-column
                :show-overflow-tooltip="true"
                class-name="name-column"
                label="职位类别"
                prop="name"
                title="按住可拖动"
            >
                <template slot-scope="{row}">
                    <span
                        class="can-drag"
                        title="按住可拖动"
                    >{{ row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="职位数量"
                prop="jobCount"
                width="150"
            ></el-table-column>
            <el-table-column
                align="center"
                label="启用"
                prop="jobStatus"
                width="150"
            >
                <template slot-scope="{row}">
                    <el-switch
                        :disabled="row.level > 1 && row.status == 'disable'"
                        :value="transformEnable(row.enable)"
                        @change="(val) => handleStatusChange(row, val)"
                        v-if="row.id != -1"
                    ></el-switch>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="操作"
            >
                <template slot-scope="scope">
                    <div class="job-category-operate">
                        <span
                            @click="mergeJobCategory(scope.row)"
                            class="text-operate-btn"
                            v-if="scope.row.id > 0"
                        >合并</span>
                        <span
                            @click="editJobCategory(scope.row)"
                            class="text-operate-btn"
                            v-if="scope.row.id > 0"
                        >编辑</span>
                        
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </template>
        </el-table>-->

        <!-- <span
                            @click="deleteJobCategory(scope.row)"
                            class="text-operate-btn red"
                            v-if="(!scope.row.children || scope.row.children.length === 0) && scope.row.id > 0"
                        >删除</span>
                        v-model="row.enable"
                        v-if="(!scope.row.children || scope.row.children.length === 0) && scope.row.id > 0"
        -->
        <!-- height="600"  fixed-->
        <dragTreeTable
            :data="{
                columns: columns,
                lists: tableData,
                custom_field: {
                    lists: 'subNodes',
                    parent_id: 'parentId',
                },
            }"
            :onDrag="onTreeDataChange"
            :beforeDragOver="handleBeforeDragOver"
            border
            v-if="tableData && tableData.length > 0"
        >
            <template
                slot="selection"
                slot-scope="{row}"
            >
                <span :title="row.name.length > 20 ? row.name: '长按可拖动职位类别'">{{row.name}}</span>
            </template>

            <template
                slot="enable"
                slot-scope="{row}"
            >
                <div
                    @click="handleClick($event)"
                    class="cell-wrap"
                >
                    <el-switch
                        :disabled="row.level > 1 && row.status == 'disable'"
                        :value="transformEnable(row.enable)"
                        @change="(val) => handleStatusChange(row, val)"
                        v-if="![-1, 0].includes(row.id)"
                    ></el-switch>
                </div>
            </template>

            <template
                slot="action"
                slot-scope="{row}"
            >
                <div
                    @click="handleClick($event)"
                    class="cell-wrap"
                >
                    <el-link
                        @click="mergeJobCategory(row)"
                        style="margin-right: 20px;"
                        type="primary"
                        v-if="row.id > 0"
                    >合并</el-link>
                    <el-link
                        @click="editJobCategory(row)"
                        type="primary"
                        v-if="row.id > 0"
                    >编辑</el-link>
                </div>
            </template>
        </dragTreeTable>
        <el-empty
            description="暂无数据"
            v-else
        ></el-empty>

        <category-merge-dialog
            @refresh="$emit('refresh')"
            ref="categoryMergeDialog"
        ></category-merge-dialog>
    </div>
</template>

<script>
import CategoryMergeDialog from '../components/category-merge-dialog.vue';
import dragTreeTable from "drag-tree-table";
import JobCategoryService from '#/js/service/jobCategoryService.js';

export default {
    components: {
        CategoryMergeDialog,
        dragTreeTable,
    },
    props: {
        tableData: {
            type: Array
        }
    },
    data () {
        return {
            loading: false,
            getRowKeys (row) {
                return row.id;
            },
            expands: [],
            columns: [
                {
                    type: "selection",
                    title: "<a>职位类别</a>",
                    // field: "name",
                    flex: 1,
                    align: "left",
                    titleAlign: "left",
                },
                {
                    title: "职位数量",
                    field: "jobCount",
                    width: 200,
                    align: "center"
                },
                {
                    title: "启用",
                    field: "enable ",
                    width: 200,
                    align: "center",
                    type: "enable",
                },
                {
                    title: "操作",
                    type: "action",
                    width: 500,
                    align: "center",
                    // actions: [
                    //     {
                    //         text: "合并",
                    //         onclick: (item) => {
                    //             console.log(`merge:`, item);
                    //         },
                    //         formatter: item => {
                    //             return "<i>合并</i>";
                    //         }
                    //     },
                    // ]
                },
            ],
            tempTable: [],

        };
    },
    mounted () { },
    methods: {
        flattenTree (tree) {
            const result = [];

            function traverse (node) {
                result.push(node);
                if (node.subNodes) {
                    node.subNodes.forEach(traverse);
                }
            }

            tree.forEach(traverse);
            return result;
        },


        mergeJobCategory (item) {
            this.$refs.categoryMergeDialog.show(item);
        },
        editJobCategory (item) {
            this.$emit("editCategory", item);
        },
        deleteJobCategory (item) {
            this.$confirm(`确定删除“${item.name}”？删除后，该类别下的绑定职位将变成“未知”。`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit("deleteCategory", item);
            }).catch(err => { });
        },

        showWraning () {
            return new Promise((resolve, reject) => {
                this.$confirm(`禁用类别后，该类别下子类别将同时禁用，是否确认禁用操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        resolve(true);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        },
        async handleStatusChange (row, val) {
            console.log(row, val);
            if (!val) {
                await this.showWraning();
            }

            const params = {
                id: row.id,
                enabled: !val ? 1 : 0, // 0为启用，1为禁用
            };
            this.$parent.$refs.jobSetTable.loading = true;
            JobCategoryService
                .jobCategoriesEnable(params)
                .then(res => {
                    console.log(`jobCategoriesEnable:`, res);
                    shortTips(`${row.name}状态修改成功`);
                    this.$parent.$refs.jobSetTable.loading = false;

                    this.$emit('update-row', row, 'enable', val);
                    // this.$emit("refresh");
                })
                .catch(e => {
                    this.$parent.$refs.jobSetTable.loading = false;
                });
        },
        handleClick (event) {
            event.stopPropagation();
        },
        transformEnable (val) {
            return val == 0;
        },
        handleBeforeDragOver(curRow, targetRow, whereInsert) {
            this.tempTable = JSON.parse(JSON.stringify(this.tableData));
            // console.log(`tempTable:`, this.tempTable);
        },

        onTreeDataChange (newList, curDragItem, taggetItem, whereInsert) {
            console.log(`arg----------------`);
            console.log(newList);
            console.log(curDragItem);
            console.log(taggetItem);
            console.log(whereInsert);
            this.$emit('after-drag', newList);
            this.categoryMove(curDragItem, taggetItem, whereInsert, newList);
        },

        categoryMove (curDragItem, taggetItem, whereInsert, newList) {
            const params = {
                sourceId: curDragItem.id,
                targetId: taggetItem.id,
                isMoveIn: whereInsert == 'center',
                whereInsert: whereInsert,
            };

            this.$parent.$refs.jobSetTable.loading = true;
            JobCategoryService
                .jobCategoriesMove(params)
                .then(res => {
                    // shortTips(`${curDragItem.name} 移动成功`);
                })
                .catch(e => {
                    shortTips(e?.msg || '操作失败！')
                    this.$emit('after-drag', this.tempTable);
                })
                .finally(() => {
                    this.$parent.$refs.jobSetTable.loading = false;
                });
        },
    }
}
</script>

<style lang="scss" scope>
.job-set-table {
    // height: 60.6% !important;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    letter-spacing: 0px;
    // height: 100%;
    /deep/ .el-table {
        background-color: transparent;
        .el-table__body-wrapper {
            background-color: #fff;
        }
        .name-column .cell {
            padding-left: 20px;
        }

        th {
            padding: 0;
            height: 42px;
            line-height: 42px;
            background-color: #e8e8e8;
            border-color: #ccc;
            color: #666;
        }
        .job-category-operate {
            .text-operate-btn + .text-operate-btn {
                margin-left: 20px;
            }
        }
        .el-table__empty-block {
            .el-table__empty-text {
                line-height: inherit;
            }
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg')
                    no-repeat;
                width: 240px;
                height: 228px;
            }
            .empty-data {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }
    }
    .can-drag {
        cursor: grab;
    }
    .job-category-operate {
        .text-operate-btn {
            margin-left: 20px;
        }
    }
    // drop placeholder的css类名
    .sortable-ghost {
        background: orange;
    }
    // 被选中项的css 类名
    .sortable-chosen {
        // background: $primary;
        background: #f0f9eb;
    }
    .sortable-drag {
        background: greenyellow !important;
    }

    .drag-tree-table {
        font-size: 14px;
    }
}
.cell-wrap {
}
</style>