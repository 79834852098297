var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: _vm.isEdit ? "编辑类别" : "添加类别",
        width: "500px",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        "custom-class": "category-manage-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "jobCategoryForm",
          staticClass: "job-category-from",
          attrs: {
            "label-width": "80px",
            "label-position": "left",
            model: _vm.jobCategoryForm,
            rules: _vm.jobCategoryFormRules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "类别名称", prop: "jobCategoryName" } },
            [
              _c("el-input", {
                staticClass: "parent-category-input",
                attrs: { placeholder: "请填写", maxlength: 50 },
                model: {
                  value: _vm.jobCategoryForm.jobCategoryName,
                  callback: function ($$v) {
                    _vm.$set(_vm.jobCategoryForm, "jobCategoryName", $$v)
                  },
                  expression: "jobCategoryForm.jobCategoryName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上级类别", prop: "parentId" } },
            [
              _c("el-cascader", {
                ref: "parentCategorySelect",
                staticClass: "parent-category-select",
                attrs: {
                  "popper-class": "category-select-popper",
                  placeholder: "请选择",
                  disabled: _vm.isEdit,
                  options: _vm.jobCategoryList,
                  props: {
                    value: "id",
                    label: "name",
                    children: "subNodes",
                    checkStrictly: true,
                    emitPath: false,
                  },
                },
                on: {
                  "visible-change": _vm.handleVisibleChange,
                  change: _vm.handleParentCategory,
                },
                model: {
                  value: _vm.jobCategoryForm.parentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.jobCategoryForm, "parentId", $$v)
                  },
                  expression: "jobCategoryForm.parentId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }